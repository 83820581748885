<template>
  <div>
    <!-- 搜索栏 -->
    <div class="searchContainer">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <el-select v-model="dataForm.anchorId" placeholder="输入主播名称筛选" size="mini" style="width: 170px;" filterable>
            <el-option v-for="item in compereList" :key="item.SecUid" :label="item.AnchorName" :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对比时间">
          <el-date-picker v-model="searchDate" type="daterange" range-separator="~" start-placeholder="开始时间"
            end-placeholder="结束时间" align="right" size="mini" style="width: 200px;" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain @click="search">查找</el-button>
    </div>
    <!-- 视频列表 -->
    <div>
      <!-- 列表标题 -->
      <div class="listTitleContainer">
        <div style="flex: 2;">对比1</div>
        <div style="flex: 2;">对比2</div>
        <div style="flex: 2;text-align: center;">对比时间</div>
        <div style="width: 90px;text-align: center;">操作</div>
      </div>
      <!-- 列表 -->
      <div class="tableContainer">
        <div v-for="item in contrastList" :key="item.Id" class="listRowContainer">
          <div class="fileColContainer" v-if="item.anchorInfoOne">
            <img :src="item.anchorInfoOne.AnchorAvatar" class="videoImg">
            <div class="fileNameContainer">
              <div class="videoNameText">{{ item.anchorVideoOne.VideoName }}</div>
              <div class="compereNameText">{{ item.anchorInfoOne.AnchorName }}</div>
            </div>
          </div>
          <div class="fileColContainer" v-else>
            <img src="@/assets/imgs/video.png" class="videoImg">
            <div class="fileNameContainer">
              <div class="videoNameText">{{ item.fileInfoOne.fileName }}</div>
            </div>
          </div>
          <div class="fileColContainer" v-if="item.anchorInfoOne">
            <img :src="item.anchorInfoTwo.AnchorAvatar" class="videoImg">
            <div class="fileNameContainer">
              <div class="videoNameText">{{ item.anchorVideoTwo.VideoName }}</div>
              <div class="compereNameText">{{ item.anchorInfoTwo.AnchorName }}</div>
            </div>
          </div>
          <div class="fileColContainer" v-else>
            <img src="@/assets/imgs/video.png" class="videoImg">
            <div class="fileNameContainer">
              <div class="videoNameText">{{ item.fileInfoTwo.fileName }}</div>
            </div>
          </div>
          <div class="analysisDateColContainer">
            <div>{{ item.ContrastTime.substring(0, 10) }}</div>
            <div>{{ item.ContrastTime.substring(10) }}</div>
          </div>
          <div class="operateColContainer">
            <div class="lookResult" @click="toContrast(item.Id)">查看对比结果</div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div style="position: absolute; bottom: 16px;left: 0;right: 0;">
        <el-pagination style="text-align: center; margin-top: 10px" @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplayClientAnalysisFinishList',

  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      searchDate: [],
      resourceTypeList: [
        { value: 0, label: "抖音" },
        { value: 1, label: "快手" },
      ],
      contrastList: [], // 对比列表
      compereList: [], // 主播列表
      dataForm: {
        anchorId: -1,
      }
    };
  },

  mounted() {
    this.getCompereList();
    this.getContrastList();
  },

  methods: {
    search() {
      this.pageIndex = 1;
      this.getContrastList();
    },
    // 获取主播列表
    getCompereList() {
      this.compereList = [];
      let requestData = {
        pageIndex: 1,
        pageSize: 500,
        anchorName: "",
        recordStatus: -1
      }
      this.$httpClient.compere.getpageanchor(requestData).then((res) => {
        if (res.code == 0) {
          this.compereList.push({
            Id: -1,
            AnchorName: "全部"
          });
          if (res.data.DataList && res.data.DataList.length > 0) {
            res.data.DataList.forEach(item => {
              this.compereList.push(item);
            })
          }
          this.currentAnchorId = -1;
        }
      })
    },
    // 获取对比页列表数据
    getContrastList() {
      this.contrastList = [];
      let requestData = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        anchorId: this.dataForm.anchorId,
        contrastStartDate: "",
        contrastEndDate: "",
      }

      if (this.searchDate && this.searchDate.length > 0) {
        requestData.contrastStartDate = this.searchDate[0];
        requestData.contrastEndDate = this.searchDate[1];
      }

      this.$httpClient.video.getcontrastpage(requestData).then(res => {
        if (res.code == 0) {
          this.totalCount = res.data.Total;
          this.contrastList = res.data.DataList;
        }
      })
    },
    // 跳转到对比页
    toContrast(contrastId) {
      this.$router.push({ path: "/replay/contrast", query: { "contrastId": contrastId } });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getContrastList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getContrastList();
    },
  },
};
</script>

<style scoped lang="less">
.lookResult {
  width: 88px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #0077FF;
  font-size: 12px;
  color: #0077FF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.operateColContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.analysisDateColContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
  font-weight: 400;
  font-size: 13px;
  color: #2E3742;
}


.compereNameText {
  font-weight: 400;
  font-size: 13px;
  color: #677583;
  margin-top: 4px;
}

.videoNameText {
  font-weight: 400;
  font-size: 14px;
  color: #2E3742;
}

.fileNameContainer {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.videoImg {
  width: 40px;
  height: 40px;
}

.fileColContainer {
  display: flex;
  flex: 2;
  align-items: center;
}

.listRowContainer {
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-top: 20px;
}

.tableContainer::-webkit-scrollbar {
  display: none;
}

.tableContainer {
  max-height: calc(100vh - 226px);
  overflow-y: auto;
}

.listTitleContainer {
  font-weight: 500;
  font-size: 12px;
  color: #2E3742;
  height: 28px;
  background: #F5F7F9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-top: 4px;
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/deep/ .el-form-item {
  margin-bottom: 0px;
}

/deep/ .el-range-separator {
  width: 10%;
}
</style>