<template>
    <div>
        <!-- 导航栏 -->
        <div class="analysisNavContainer">
            <img src="@/assets/imgs/zuojiantou.png" class="analysisNavImg" @click="toPage('tabs')">
            <div class="analysisNavText">复盘分析</div>
        </div>
        <!-- 文件分析 -->
        <div class="videoAnalysisContainer">

            <div class="analysisItemContainer">
                <analysis-item :sentenceMarkData="sentenceMarkData" :videoWidth="'18%'" v-if="showAnalysis"
                    @countWords="countWords"></analysis-item>
            </div>

            <!-- 关键词/敏感词列表 -->
            <!-- <div class="wordsSummaryContainer" v-if="wordsInfo.markCrux || wordsInfo.markSensitive">
                <div class="wordsExportContainer">
                    <div>关键词汇总</div>
                    <div v-if="wordsInfo.wordsList && wordsInfo.wordsList.length > 0">
                        <el-button @click="exportExcel" style="margin-left: 10px;" size="mini"
                            type="primary">导出词语列表</el-button>
                    </div>
                </div>
                <div class="wordsSummaryBodyContainer">
                    <div class="wordsSummaryTitleContainer"
                        :style="wordsInfo.wordsList && wordsInfo.wordsList.length > 3 ? 'margin-right:4px' : ''">
                        <div class="wordsSummaryTitleItem border-left border-top border-bottom">类型</div>
                        <div class="wordsSummaryTitleItem border-left border-top border-bottom">词语</div>
                        <div class="wordsSummaryTitleItem border-left border-top border-bottom">平台</div>
                        <div class="wordsSummaryTitleItem border-left border-top border-bottom">来源</div>
                        <div class="wordsSummaryTitleItem border-left border-top border-bottom">分类/等级</div>
                        <div class="wordsSummaryTitleItem border-left border-top border-right border-bottom">次数
                        </div>
                    </div>
                    <div class="wordsSummaryContentContainer">
                        <div v-for="(item, index) in wordsInfo.wordsList" :key="index"
                            class="wordsSummaryContentItemContainer">
                            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.wordsTypeStr }}
                            </div>
                            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.name }}</div>
                            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.platformTypeStr
                                }}</div>
                            <div class="wordsSummaryContentItem border-left border-bottom">{{ item.resourceTypeStr
                                }}</div>
                            <div class="wordsSummaryContentItem border-left border-bottom"> {{ item.wordsType === 0
                                ? item.levelStr : item.typeStr }}</div>
                            <div class="wordsSummaryContentItem border-left border-right border-bottom">{{
                                item.countNum }}
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import myUtils from '../../../../utils/utils';
import analysisItem from "../../../commonComponent/analysis-item.vue"
export default {
    components: {
        analysisItem
    },
    data() {
        return {
            // 文字段落信息
            sentenceMarkData: {
                sentenceMarkList: [],
                fileInfo: {},
                playUrl: ""
            },
            // 关键词/敏感词数据
            wordsInfo: {
                wordsList: [], // 关键词/敏感词列表
                cruxWordsNum: 0, // 关键词个数
                sensitiveWordsNum: 0, // 敏感词个数
                markCrux: true, // 是否标注关键词
                markSensitive: true, // 是否标注敏感词
            },
            fileId: "",
            showAnalysis: false,
        };
    },
    mounted() {
        // 获取文件id
        this.fileId = this.$store.state.fileAnalysisId;
        if (this.fileId) {
            this.getFileAnalysis();
        }
    },
    methods: {
        // 导出词语列表excel
        exportExcel() {
            let requestData = {
                dataJson: encodeURIComponent(JSON.stringify(this.wordsInfo.wordsList)),
                type: 1,
                id: this.fileId
            }
            this.$httpClient.export.wordsexcel(requestData).then(res => {
                if (res.code == 0 && res.data) {
                    this.$message.success("导出成功");
                }
            })
        },
        // 获取文件分析信息
        getFileAnalysis() {
            this.sentenceMarkData.sentenceMarkList = [];
            this.$httpClient.uploadFile.lockanalysis({ fileId: this.fileId }).then((res) => {
                if (res.code == 0) {
                    // 视频/音频播放地址
                    this.sentenceMarkData.playUrl = res.data.playUrl;
                    // 文件信息
                    this.sentenceMarkData.fileInfo = res.data.uploadFile;
                    if (this.sentenceMarkData.fileInfo.fileDuration) {
                        this.sentenceMarkData.fileInfo.fileDuration = myUtils.toformatTime(this.sentenceMarkData.fileInfo.fileDuration * 1000);
                    }
                    // 段落
                    if (res.data.fileAudioaAlyses && res.data.fileAudioaAlyses.length > 0) {
                        // 添加进数组并排序
                        res.data.fileAudioaAlyses.forEach(item => {
                            if (item.status == 0) {
                                let obj = JSON.parse(item.dataJson);
                                obj.markContent = item.content;
                                this.sentenceMarkData.sentenceMarkList.push(obj);
                            }
                        });
                        this.sentenceMarkData.sentenceMarkList.sort((a, b) => a.currentSort - b.currentSort);

                        // 设置段落的开始时间和结束时间(文本文件不设置)
                        if (this.sentenceMarkData.sentenceMarkList.length > 0 && this.sentenceMarkData.fileInfo.fileType != 2) {
                            this.sentenceMarkData.sentenceMarkList.forEach((item, index) => {
                                item.markContent = item.content;
                                if (index == 0) {
                                    item.startTime = 0;
                                } else {
                                    item.startTime = item.items[0].startTime;
                                }
                                item.endTime = item.items[item.items.length - 1].endTime;
                            })
                        }
                    }
                    this.showAnalysis = true;

                    // this.countWords();
                }
            });
        },
        // 整理关键词、敏感词数据
        countWords(wordsInfo) {
            this.wordsInfo = wordsInfo;
            // if (this.wordsInfo.wordsList && this.wordsInfo.wordsList.length > 0) {
            //     let arr = [];
            //     this.wordsInfo.wordsList.forEach(item => {
            //         if (this.wordsInfo.markCrux && item.wordsType == 1) {
            //             arr.push(item);
            //         }
            //         if (this.wordsInfo.markSensitive && item.wordsType == 0) {
            //             arr.push(item);
            //         }
            //     });
            //     this.wordsInfo.wordsList = arr;
            // }
            // this.wordsInfo.wordsList = [];
            // this.wordsInfo.cruxWordsNum = 0;
            // this.wordsInfo.sensitiveWordsNum = 0;

            // if (this.sentenceMarkData.sentenceMarkList && this.sentenceMarkData.sentenceMarkList.length > 0) {
            //     this.sentenceMarkData.sentenceMarkList.forEach(item => {

            //         let wordsList = item.wordsList;
            //         if (wordsList && wordsList.length > 0) {

            //             wordsList.forEach(wordsItem => {
            //                 if (this.wordsInfo.wordsList.length < 1) {
            //                     this.wordsInfo.wordsList.push(wordsItem);
            //                 } else {
            //                     // 判断数组里面是否已经存在当前关键词/敏感词，如果是，则只增加出现数量
            //                     let exist = false;
            //                     this.wordsInfo.wordsList.forEach(haveItem => {
            //                         if (haveItem.name == wordsItem.name && haveItem.wordsType == wordsItem.wordsType) {
            //                             exist = true;
            //                             haveItem.countNum += wordsItem.countNum;
            //                         }
            //                     });
            //                     if (!exist) {
            //                         this.wordsInfo.wordsList.push(wordsItem);
            //                     }
            //                 }
            //             })
            //         }
            //     });

            // }
            // // 排序
            // this.wordsInfo.wordsList.sort((a, b) => b.countNum - a.countNum);
        },
        // 毫秒时间戳转成时分秒格式
        toformatTime(val) {
            return myUtils.toformatTime(val);
        },
        toPage(url) {
            this.$emit("toPage", url);
        }
    },
};
</script>
<style scoped>
.wordsExportContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.analysisItemContainer {
    width: 100%;
    /* min-height: calc(100vh - 250px); */
}

.border-right {
    border-right: 0.5px solid #ccc;
}

.border-left {
    border-left: 0.5px solid #ccc;
}

.border-bottom {
    border-bottom: 0.5px solid #ccc;
}

.border-top {
    border-top: 0.5px solid #ccc;
}

.wordsSummaryTitleItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F7F9;
    font-size: 13px;
    font-weight: 400;
    color: #2E3742;
    height: 28px;
}

.wordsSummaryContentItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #2E3742;
    height: 28px;
}

.wordsSummaryContentItemContainer {
    display: flex;
    align-items: center;
}

.wordsSummaryContentContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.wordsSummaryContentContainer::-webkit-scrollbar {
    width: 4px;
}

.wordsSummaryContentContainer {
    height: 90px;
    overflow-y: auto;
}

.wordsSummaryTitleContainer {
    display: flex;
    align-items: center;

}

.wordsSummaryBodyContainer {
    margin-top: 10px;
}

.wordsSummaryContainer {
    margin-top: 14px;
    font-weight: 600;
    font-size: 14px;
    color: #2E3742;

}

.videoAnalysisContainer {
    margin-top: 8px;
}


.analysisNavText {
    font-weight: 500;
    font-size: 16px;
    color: #2E3742;
    margin-left: 10px;
}

.analysisNavImg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.analysisNavContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DCE0E7;
    padding-bottom: 10px;
}
</style>